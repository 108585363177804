import { ComponentProps } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { OldHeading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useTheme } from '@oui/app-core/src/styles';

import { PlatformScreen } from '@src/components/PlatformScreen';
import { ProductVariantsInput } from '@src/components/ProductVariantsInput';
import TextInput from '@src/components/TextInput';
import { useOrganization } from '@src/screens/Organization';
import { CliniciansScreenProps } from '@src/types';

import {
  UpdateOrganizationMutationVariables,
  useUpdateOrganizationMutation,
} from './EditOrganization.graphql.generated';

function Subheading(props: ComponentProps<typeof Text>) {
  const { theme } = useTheme();
  return <Text {...props} size={17} color={theme.color.dark} weight="bold" />;
}

export function EditOrganization(props: CliniciansScreenProps<'EditOrganization'>) {
  const { data } = useOrganization(props.route.params?.organizationID);

  const [updateOrganization, { error: updateOrganizationError }] = useUpdateOrganizationMutation();
  const {
    validate: validateOrganization,
    bind,
    data: organizationData,
    humanErrors,
  } = useForm<UpdateOrganizationMutationVariables['organization']>(
    {
      productVersion: data?.organizationByID?.productVersion,
      productVariants: data?.organizationByID?.productVariants,
      onboardingVariant: data?.organizationByID?.onboardingVariant,
      caringContactsEnabled: data?.organizationByID?.caringContactsEnabled,
      name: data?.organizationByID?.name,
      address: [],
      contactPoint: [],
    },
    {
      merger: (originalValue: any, formValue: any) => {
        if (Array.isArray(originalValue)) {
          return formValue;
        }
      },
    },
  );

  return (
    <PlatformScreen
      crumbs={[
        { label: 'Organizations', to: 'organizations' },
        { label: 'Edit Organization', to: '' },
      ]}
    >
      <OldHeading text="Edit organization" />
      {!data ? null : (
        <View
          spacing={30}
          style={{
            backgroundColor: 'white',
            paddingHorizontal: 40,
            paddingVertical: 20,
            marginTop: 20,
            borderRadius: 10,
            maxWidth: 600,
            flex: 1,
          }}
        >
          {updateOrganizationError || Object.keys(humanErrors).length ? (
            <ErrorPresenter error={updateOrganizationError} formErrors={humanErrors} />
          ) : null}
          <TextInput
            {...bind('name', { validator: { type: 'present' }, label: 'Organization name*' })}
          />
          <View spacing={12}>
            <Subheading text="Products" />
            <Text text="Intervention" weight="semibold" />
            <ProductVariantsInput
              value={organizationData.productVariants ?? []}
              onChangeValue={bind('productVariants', { label: '' }).onChangeValue}
              caringContactsEnabled={organizationData.caringContactsEnabled!}
              onChangeCaringContactsEnabled={
                bind('caringContactsEnabled', { label: '' }).onChangeValue
              }
              onboardingVariant={organizationData.onboardingVariant!}
              onChangeOnboardingVariant={bind('onboardingVariant', { label: '' }).onChangeValue}
            />
          </View>
          <Button
            alignSelf="center"
            style={{ marginTop: 12 }}
            text="Update organization"
            onPress={async () => {
              if (validateOrganization()) {
                await updateOrganization({
                  variables: {
                    organizationID: props.route.params.organizationID,
                    organization: organizationData,
                  },
                });
                props.navigation.push('Organization', {
                  organizationID: props.route.params.organizationID,
                });
              }
            }}
          />
        </View>
      )}
    </PlatformScreen>
  );
}
