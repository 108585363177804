import * as Sentry from '@sentry/core';
import { useEffect, useState } from 'react';

import { getIdTokenResult } from '@oui/app-core/src/lib/auth';
import { parseJwt } from '@oui/lib/src/parseJwt';

export function useIdTokenResult() {
  const [idTokenResult, setIdTokenResult] = useState({ token: '', claims: {} });
  useEffect(() => {
    getIdTokenResult()
      .then((res) => {
        if (res) {
          try {
            const decoded = parseJwt(res);
            setIdTokenResult({
              token: res,
              ...decoded,
            });
          } catch (e) {
            setIdTokenResult({
              token: res,
              claims: {},
            });
          }
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }, []);
  return idTokenResult;
}
