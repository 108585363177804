import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { usePatientEmailsQuery } from '@src/components/PatientSectionAdmin.graphql.generated';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';

export function PatientSectionEmails({ patientID }: { patientID: GQLUUID }) {
  const { data, loading } = usePatientEmailsQuery({
    variables: { patientID },
  });
  const emails = data?.patientByPatientID?.emails;
  const intl = useI18n();

  return (
    <View>
      <StaffTable
        keyExtractor={(item) => item.userEmailID}
        loading={loading}
        columns={[
          { label: 'Email ID', grow: 2 },
          { label: '3rd Party Service ID', grow: 2 },
          { label: 'Description', grow: 1 },
          { label: 'Created', grow: 1 },
        ]}
        data={emails}
        renderItem={({ item }) => {
          return (
            <View style={[ListItemStyle]}>
              <TableCell grow={2}>
                <Text text={item.userEmailID} />
              </TableCell>
              <TableCell grow={2}>
                <Text text={item.thirdPartyServiceID} />
              </TableCell>
              <TableCell grow={1}>
                <Text text={item.description} />
              </TableCell>
              <TableCell grow={1}>
                <Text text={intl.formatDate(item.createdAt)} />
              </TableCell>
            </View>
          );
        }}
      />
    </View>
  );
}
