import { ComponentProps, ReactNode } from 'react';
import { View } from 'react-native';

import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { useTheme } from '@oui/app-core/src/styles';

import { Breadcrumbs } from '@src/components/Breadcrumbs';

export function PlatformScreen({
  children,
  disableScroll,
  testID,
  innerTestID,
  crumbs,
}: {
  children?: ReactNode;
  disableScroll?: boolean;
  testID?: string;
  innerTestID?: string;
  crumbs: ComponentProps<typeof Breadcrumbs>['crumbs'];
}) {
  const { theme } = useTheme();
  return (
    <View
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      testID={innerTestID ? testID : undefined}
    >
      <Breadcrumbs crumbs={crumbs} />
      {disableScroll ? (
        <View
          testID={innerTestID ?? testID}
          style={{
            flex: 1,
            paddingHorizontal: 20,
            paddingVertical: 16,
          }}
        >
          {children}
        </View>
      ) : (
        <ScrollView
          testID={innerTestID ?? testID}
          style={{ alignSelf: 'stretch' }}
          contentContainerStyle={{
            flexGrow: 1,
            paddingHorizontal: 20,
            paddingVertical: 16,
          }}
        >
          {children}
        </ScrollView>
      )}
    </View>
  );
}
