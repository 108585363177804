import { useNavigation } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import { useMemo, useState } from 'react';

import { useAppContext } from '@oui/app-core/src/components/AppContext';
import { Button } from '@oui/app-core/src/components/Button';
import { Modal } from '@oui/app-core/src/components/Modal';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { IntlShape, useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { getTreatmentList } from '@oui/lib/src/treatmentList';
import {
  OnboardingVariant,
  ProductVariant,
  ProductVersion,
} from '@oui/lib/src/types/graphql.generated';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { Icon } from '@src/components/Icon';
import { ListItemStyle, StaffTable, TableCell } from '@src/components/StaffTable';
import { usePatient } from '@src/hooks/usePatient';
import { CliniciansScreenProps, ContentType } from '@src/types';

export function getSessionsForProductVersion({
  $t,
  productVersion,
  productVariant,
  productStatic,
}: {
  $t: IntlShape['$t'];
  productVersion: ProductVersion | undefined;
  productVariant: ProductVariant | undefined;
  productStatic: boolean | undefined;
}): Array<{ contentType: ContentType; title: string; ordinal?: number }> {
  if (productVersion === ProductVersion.AVIVA_2_0) {
    if (!productVariant || typeof productStatic !== 'boolean') return [];
    return getTreatmentList({ $t, productVariant, productStatic });
  }
  Sentry.captureException(new Error('Invalid productVariant'));
  return [];
}

export function PatientSectionAvivaProgress({ patientID }: { patientID: GQLUUID }) {
  const { theme } = useTheme();
  const [sampleVideoKey, setSampleVideoKey] = useState<ContentType | ''>('');
  const { navigate } = useNavigation<CliniciansScreenProps<'Patient'>['navigation']>();
  const { locale } = useAppContext();
  const { $t } = useI18n();
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
  });
  const patient = usePatient(patientID, { fetchPolicy: 'cache-only' });
  const productVersion =
    patient.data?.patientByPatientID?.patient.productVersion ?? ProductVersion.AVIVA_2_0;
  const onboardingVariant = patient.data?.patientByPatientID?.patient.onboardingVariant;
  const sessions = getSessionsForProductVersion({
    $t,
    productVariant: patient.data?.patientByPatientID?.patient.productVariant,
    productVersion: patient.data?.patientByPatientID?.patient.productVersion,
    productStatic: patient.data?.patientByPatientID?.patient.productStatic,
  });
  const progress = useMemo(() => {
    const items = patient.data?.patientByPatientID?.progress ?? [];
    return items.reduce<Partial<Record<ContentType, (typeof items)[number]>>>((carry, p) => {
      carry[p.content as ContentType] = p;
      return carry;
    }, {});
  }, [patient.data?.patientByPatientID?.progress]);

  return (
    <>
      <StaffTable
        keyExtractor={(item) => item.contentType}
        loading={patient.loading}
        columns={[
          { label: '#', grow: 0 },
          { label: 'Session', grow: 2 },
          'Patient status',
          'Option',
        ]}
        data={sessions}
        renderItem={({ item, index }) => {
          const progressItem = progress[item.contentType];
          const isDisabled =
            !([ContentType.MYSTORYMYPLAN, ContentType.MYPLAN] as ContentType[]).includes(
              item.contentType,
            ) && !(progress.MYSTORYMYPLAN?.completed || progress.MYPLAN?.completed);
          return (
            <View
              testID={
                isDisabled
                  ? `PatientSectionAvivaProgress_disabledRow_${item.contentType}`
                  : `PatientSectionAvivaProgress_row_${item.contentType}`
              }
              style={[
                ListItemStyle,
                {
                  opacity: isDisabled ? 0.3 : undefined,
                },
              ]}
              pointerEvents={isDisabled ? 'none' : undefined}
            >
              <TableCell grow={0}>
                <Text
                  text={
                    item.ordinal?.toString() ?? productVersion === ProductVersion.AVIVA_2_0
                      ? (index + 1).toString()
                      : index.toString()
                  }
                />
              </TableCell>
              <TableCell grow={2}>
                <Text text={item.title} weight="semibold" />
              </TableCell>
              <TableCell>
                {!progressItem || progressItem.completion === 0 ? (
                  <Text text={'Not started'} />
                ) : !progressItem.completed ? (
                  <Text
                    text={`Started ${dateFormatter.format(new Date(progressItem.updatedAt!))}`}
                  />
                ) : (
                  <View row flex={1}>
                    <Icon name="check" color={theme.color.success} size={32} />
                    <Text
                      text={`Completed ${dateFormatter.format(new Date(progressItem.updatedAt!))}`}
                    />
                  </View>
                )}
              </TableCell>
              <TableCell>
                {(item.contentType === ContentType.MYSTORYMYPLAN ||
                  item.contentType === ContentType.MYPLAN) &&
                onboardingVariant === OnboardingVariant.COLLABORATIVE ? (
                  <Button
                    alignSelf="flex-start"
                    size="small"
                    variant="text"
                    text={progressItem ? 'View' : 'Start'}
                    iconRight={progressItem ? undefined : 'arrow-right'}
                    onPress={() => {
                      navigate('MyStoryMyPlan', {
                        screen: 'MyStoryMyPlanOverview',
                        params: {
                          patientID,
                        },
                      });
                    }}
                    testID={
                      progressItem ? 'Patient_viewMyStoryMyPlan' : 'Patient_startMyStoryMyPlan'
                    }
                    style={{ paddingVertical: 0 }}
                  />
                ) : productVersion === ProductVersion.AVIVA_2_0 ? (
                  <Button
                    size="small"
                    text="Sample video"
                    onPress={() => setSampleVideoKey(item.contentType)}
                    variant="text"
                    style={{ paddingVertical: 0 }}
                  />
                ) : null}
              </TableCell>
            </View>
          );
        }}
      />
      {sampleVideoKey ? (
        <Modal
          visible
          onRequestClose={() => setSampleVideoKey('')}
          heading={sessions.find((s) => s.contentType === sampleVideoKey)?.title || 'Sample video'}
        >
          <video
            controls
            src={`https://storage.googleapis.com/asset.oui.dev/session_walkthrough/${sampleVideoKey}.mp4`}
            style={{ width: '100%', maxHeight: 500 }}
          />
        </Modal>
      ) : null}
    </>
  );
}
