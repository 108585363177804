import { useFocusEffect } from '@react-navigation/native';
import hexToRgba from 'hex-to-rgba';
import noop from 'lodash/noop';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Clipboard, TouchableOpacity } from 'react-native';
import { useDebounce } from 'use-debounce';

import { Accordion } from '@oui/app-core/src/components/Accordion';
import { useAppContext } from '@oui/app-core/src/components/AppContext';
import { Button } from '@oui/app-core/src/components/Button';
import { RNSlider } from '@oui/app-core/src/components/RNSlider';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Environment, environment } from '@oui/app-core/src/constants';
import { useAppState } from '@oui/app-core/src/hooks/useAppState';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { useTheme } from '@oui/app-core/src/styles';
import { ContactPointSystem } from '@oui/lib/src/types/graphql.generated';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import Divider from '@src/components/Divider';
import { Icon } from '@src/components/Icon';
import { PatientSectionAvivaProgress } from '@src/components/PatientSectionAvivaProgress';
import { PatientSectionCareTeam } from '@src/components/PatientSectionCareTeam';
import { PatientSectionCaringContacts } from '@src/components/PatientSectionCaringContacts';
import { PatientSectionDetails } from '@src/components/PatientSectionDetails';
import { PatientSectionDevices } from '@src/components/PatientSectionDevices';
import { PatientSectionEmails } from '@src/components/PatientSectionEmails';
import { PatientSectionNotifications } from '@src/components/PatientSectionNotifications';
import { PatientSectionQuestionnaires } from '@src/components/PatientSectionQuestionnaires';
import { PatientSupportInvitationForm } from '@src/components/PatientSupportInvitationForm';
import { PlatformScreen } from '@src/components/PlatformScreen';
import { PlatformTabs } from '@src/components/PlatformTabs';
import { usePatient } from '@src/hooks/usePatient';
import {
  IssueActivationTokenMutation,
  useIssueActivationTokenMutation,
} from '@src/screens/Patient.graphql.generated';
import { CliniciansScreenProps } from '@src/types';

function isNotNull<T>(item: T | null): item is T {
  return item !== null;
}

function Step({
  num,
  title,
  description,
  children,
}: {
  num: number;
  title: string;
  description: string;
  children: ReactNode;
}) {
  return (
    <View row style={{ alignItems: 'flex-start' }} spacing={20}>
      <View style={{ height: 29, justifyContent: 'center' }}>
        <Text text={`Step ${num}`} />
      </View>
      <View spacing={10} flex={1}>
        <Text text={title} weight="semibold" size={21} style={{ lineHeight: 29 }} />
        <Text text={description} />
        <View
          style={{
            borderRadius: 10,
            backgroundColor: '#f7f7f7',
            padding: 25,
            alignItems: 'center',
          }}
        >
          {children}
        </View>
      </View>
    </View>
  );
}

function ActivationCodeTimer({
  code,
  expiresAt,
  onExpired,
}: {
  code: string;
  expiresAt: number;
  onExpired: () => void;
}) {
  const { theme } = useTheme();
  const initRef = useRef(Date.now());
  const [value, setValue] = useState(Date.now);
  const minutesRemaining = Math.round((expiresAt - value) / 60000);
  const onExpiredRef = useRef(onExpired);
  onExpiredRef.current = onExpired;

  const updateSlider = useCallback(() => {
    const now = Math.min(Date.now(), expiresAt);
    setValue(now);
    const isExpired = now >= expiresAt;
    if (isExpired) onExpiredRef.current();
    return isExpired;
  }, [expiresAt]);

  useAppState((nextState) => {
    if (nextState === 'active') {
      updateSlider();
    }
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    function run() {
      const _isExpired = updateSlider();
      if (!_isExpired) {
        timeout = setTimeout(run, 10000);
      }
    }
    run();
    return () => clearTimeout(timeout);
  }, [updateSlider]);

  return (
    <>
      <Text
        testID="Patient_registrationSteps_activationCode"
        text={`${code.substr(0, code.length / 2)}  ${code.substr(code.length / 2)}`}
        size={32}
        weight="semibold"
      />
      <RNSlider
        animateTransitions={true}
        minimumValue={initRef.current}
        maximumValue={expiresAt}
        value={value}
        onValueChange={noop}
        style={{ width: '100%' }}
        minimumTrackTintColor={theme.color.accentThree100}
        maximumTrackTintColor={theme.color.gray600}
        trackStyle={{
          height: 6,
          borderRadius: 6,
        }}
        thumbStyle={{
          height: 6,
          backgroundColor: theme.color.accentThree100,
        }}
      />
      <Text
        text={`Code expires in ${
          minutesRemaining === 1 ? '1 minute' : `${minutesRemaining} minutes`
        }`}
        color={theme.color.gray600}
        weight="semibold"
      />
    </>
  );
}

function RegistrationSteps({
  patientID,
  passwordReset,
}: {
  patientID: GQLUUID;
  passwordReset?: boolean;
}) {
  const { theme } = useTheme();
  const [activationTokenData, setActivationTokenData] = useState<
    IssueActivationTokenMutation['issueToken'] | undefined
  >();
  const { value: code, expiresAt: expiresAtStr } = activationTokenData ?? {
    code: null,
    expiresAt: '',
  };
  const [isExpired, setIsExpired] = useState(false);
  const expiresAt = expiresAtStr ? new Date(expiresAtStr).getTime() : 0;
  const [issueActivationToken] = useIssueActivationTokenMutation();

  const generateCode = useCallback(async () => {
    const result = await issueActivationToken({ variables: { patientID } });
    setActivationTokenData(result.data?.issueToken);
    setIsExpired(false);
  }, [patientID, issueActivationToken]);

  return (
    <View
      style={{ backgroundColor: 'white', padding: passwordReset ? 0 : 32, borderRadius: 16 }}
      spacing={10}
      testID="Patient_registrationSteps"
    >
      {passwordReset ? null : (
        <Step
          num={1}
          title="Direct patient to download Aviva app"
          description="Have the patient open up their phone’s browser and type in the link below. "
        >
          <Text
            text={
              environment === Environment.PRODUCTION ? 'getaviva.app' : 'Download from Firebase'
            }
            color={theme.color.primary100}
            weight="semibold"
          />
        </Step>
      )}
      {passwordReset ? null : <Divider />}
      <Step
        num={passwordReset ? 1 : 2}
        title="Give patient claim code"
        description={`Verbally give patient the code below to enter into the "Claim code" field.`}
      >
        {isExpired || !code || !expiresAt ? (
          <>
            <Button
              text="Generate code"
              onPress={generateCode}
              variant="solid"
              testID="Patient_registrationSteps_generateActivationCode"
            />
          </>
        ) : (
          <ActivationCodeTimer
            code={code}
            expiresAt={expiresAt}
            onExpired={() => setIsExpired(true)}
          />
        )}
      </Step>
    </View>
  );
}

function Label(props: Omit<ComponentProps<typeof Text>, 'size' | 'color'>) {
  const { theme } = useTheme();
  return <Text size={13} color={theme.color.gray400} {...props} weight="semibold" />;
}

type Anchor = NonNullable<CliniciansScreenProps<'Patient'>['route']['params']['anchor']>;

export function PatientV2(props: CliniciansScreenProps<'Patient'>) {
  const { theme } = useTheme();
  const patientID = props.route.params.patientID as GQLUUID;
  const { locale } = useAppContext();
  const { refetch, data, loading } = usePatient(patientID);
  const { width: _width } = useWindowDimensions();
  const [width] = useDebounce(_width, 100);
  const [patientIDCopied, setPatientIDCopied] = useState(false);
  const { isAdmin } = useCurrentUser();

  const anchor: Anchor = props.route.params.anchor ?? 'aviva';

  const patient = data?.patientByPatientID?.patient;
  const preferred = patient?.person.givenName;
  const fullName = patient ? `${preferred} ${patient.person.familyName}` : '';
  const passwordSet = data?.patientByPatientID?.lastLogin !== null;
  const isPasswordSet = !!passwordSet;

  const isTrialOrg = useMemo(() => {
    if (!data?.patientByPatientID?.careTeam.organizations) {
      return false;
    }
    return data.patientByPatientID.careTeam.organizations.some((org) => org.isTrialOrganization);
  }, [data?.patientByPatientID?.careTeam.organizations]);

  useFocusEffect(
    useCallback(() => {
      const interval = setInterval(
        () => {
          refetch();
        },
        isPasswordSet ? 10000 : 5000,
      );
      refetch();

      // expire the auto refresh after 5 minutes
      const staleTimeout = setTimeout(
        () => {
          clearInterval(interval);
        },
        60000 * (isPasswordSet ? 5 : 15),
      );

      return () => {
        clearTimeout(staleTimeout);
        clearInterval(interval);
      };
      // eslint-disable-next-line
    }, [isPasswordSet]),
  );

  const setParams = props.navigation.setParams;
  const passwordNotYetSetRef = useRef(false);
  useEffect(() => {
    if (!isPasswordSet && !loading) {
      // This page was open when password wasn't yet set
      passwordNotYetSetRef.current = true;
    } else if (isPasswordSet && passwordNotYetSetRef.current) {
      // the password is now set, after previously being unset on this pageload
      // so auto-switch to the aviva tab, and clear the passwordNotYetSetRef state
      setParams({ anchor: 'aviva' });
      passwordNotYetSetRef.current = false;
    }
  }, [loading, isPasswordSet, setParams]);

  const dateTimeFormatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const isMobile = width < 768;
  const email =
    patient?.person.email ??
    (patient?.person.contactPoint?.find((point) => point.system === ContactPointSystem.EMAIL)
      ?.value ||
      'Unknown');
  const phone =
    patient?.person.contactPoint?.find((point) => point.system === ContactPointSystem.PHONE)
      ?.value || 'Unknown';
  const accountCreated =
    data?.patientByPatientID?.patient.createdAt &&
    data.patientByPatientID.patient.createdAt !== '0001-01-01T00:00:00Z'
      ? dateTimeFormatter.format(new Date(data.patientByPatientID.patient.createdAt))
      : 'Unknown';
  const lastLogin =
    data?.patientByPatientID?.lastLogin &&
    data.patientByPatientID.lastLogin !== '1970-01-01T00:00:00Z' &&
    data.patientByPatientID.lastLogin !== '0001-01-01T00:00:00Z'
      ? dateTimeFormatter.format(new Date(data.patientByPatientID.lastLogin))
      : 'Never';

  const basicInfo = isMobile ? (
    <View style={{ backgroundColor: 'white', padding: 32, borderRadius: 16 }} spacing={20}>
      <View row childFlex={1}>
        <View>
          <Label text="Email" style={{ flex: 2 }} />
          <Text text={email} style={{ flex: 3 }} />
        </View>
        <View>
          <Label text="Phone number" style={{ flex: 2 }} />
          <Text text={phone} style={{ flex: 3 }} />
        </View>
      </View>
      <View row childFlex={1}>
        <View>
          <Label text="Account created" style={{ flex: 2 }} />
          <Text text={accountCreated} style={{ flex: 3 }} />
        </View>
        <View>
          <Label text="Last login" style={{ flex: 2 }} />
          <Text text={lastLogin} style={{ flex: 3 }} />
        </View>
      </View>
    </View>
  ) : (
    <>
      <View spacing={24} style={{ alignItems: 'center' }}>
        <View
          style={{
            width: 100,
            height: 100,
            borderRadius: 100,
            backgroundColor: hexToRgba(theme.color.accentTwo100, 0.15),
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            text={`${patient?.person.givenName?.[0] ?? ''}${patient?.person.familyName?.[0] ?? ''}`}
            size={52}
            style={{ lineHeight: 71 }}
            weight="semibold"
            color={theme.color.accentTwo100}
          />
        </View>
        <Text text={fullName} size={28} style={{ lineHeight: 38 }} weight="semibold" />
      </View>
      <View style={{ marginTop: 40 }}>
        <Label text="User ID" />
        <View row style={{ justifyContent: 'space-between' }}>
          <Text text={patientID} size={15} />
          <Button
            variant="text"
            onPress={() => {
              Clipboard.setString(patientID);
              setPatientIDCopied(true);
            }}
            text="Copy"
            textStyle={{ fontSize: 15 }}
            style={{ paddingVertical: 0 }}
          />
        </View>
        {patientIDCopied ? (
          <View row spacing={5}>
            <Icon name="check" color={theme.color.success} />
            <Text
              text="Copied to clipboard"
              color={theme.color.success}
              size={15}
              weight="semibold"
            />
          </View>
        ) : null}
      </View>
      <View style={{ marginTop: 40 }} spacing={15}>
        <View row>
          <Label text="Email" style={{ flex: 2 }} />
          <Text text={email} size={15} style={{ flex: 3 }} />
        </View>
        <View row>
          <Label text="Phone number" style={{ flex: 2 }} />
          <Text text={phone} size={15} style={{ flex: 3 }} />
        </View>
        <View row>
          <Label text="Account created" style={{ flex: 2 }} />
          <Text text={accountCreated} size={15} style={{ flex: 3 }} />
        </View>
        <View row>
          <Label text="Last login" style={{ flex: 2 }} />
          <Text text={lastLogin} size={15} style={{ flex: 3 }} />
        </View>
      </View>
    </>
  );

  return patient ? (
    <View style={{ flexDirection: 'row', flex: 1 }} testID="Patient">
      {isMobile ? null : (
        <ScrollView
          testID="Patient_basicInfoScrollView"
          style={{ padding: 34, maxWidth: 400, width: 400, minWidth: 400 }}
        >
          {basicInfo}
        </ScrollView>
      )}
      <PlatformScreen
        testID="Patient_mainScrollView"
        crumbs={[
          { label: 'Patients', to: 'patients' },
          { label: fullName, to: '' },
        ]}
      >
        <View spacing={24}>
          {isMobile ? basicInfo : null}
          {passwordSet ? null : (
            <View spacing={15}>
              <Heading text="Finish registration" level={2} />
              <RegistrationSteps patientID={patientID} />
            </View>
          )}
          <PlatformTabs
            value={anchor}
            onChangeValue={(value) => {
              props.navigation.setParams({ anchor: value });
            }}
            items={[
              { value: 'aviva', text: 'Aviva' } as const,
              isTrialOrg ? null : ({ value: 'questionnaires', text: 'Questionnaires' } as const),
              { value: 'details', text: 'Details' } as const,
              isAdmin ? ({ value: 'admin', text: 'Admin' } as const) : null,
            ].filter(isNotNull)}
            testID="Patient_tabs"
          />
          <View style={{ minHeight: 400 }}>
            {anchor === 'aviva' ? (
              <View>
                <View spacing={16}>
                  <PatientSectionAvivaProgress patientID={patientID} />

                  <PatientSupportInvitationForm patientID={patientID} />

                  <Accordion
                    heading={<Heading text="Care team" level={2} />}
                    // Wrap in TouchableOpacity so accordion doesn't collapse when clicking inside
                    content={
                      <TouchableOpacity activeOpacity={1} onPress={noop}>
                        <PatientSectionCareTeam patientID={patientID} />
                      </TouchableOpacity>
                    }
                  />
                  <Accordion
                    testID="Patient_passwordReset"
                    heading={<Heading text="Password reset" level={2} />}
                    // Wrap in TouchableOpacity so accordion doesn't collapse when clicking on code
                    content={
                      <TouchableOpacity
                        activeOpacity={1}
                        onPress={noop}
                        style={{ backgroundColor: 'white', padding: 20, borderRadius: 16 }}
                      >
                        <RegistrationSteps patientID={patientID} passwordReset />
                      </TouchableOpacity>
                    }
                  />
                </View>
              </View>
            ) : anchor === 'questionnaires' && !isTrialOrg ? (
              <View>
                <PatientSectionQuestionnaires patientID={patientID} />
              </View>
            ) : anchor === 'details' ? (
              <View>
                <PatientSectionDetails patient={patient} />
              </View>
            ) : anchor === 'admin' ? (
              <View>
                <View spacing={16}>
                  <Accordion
                    heading={<Heading text="Notifications" level={2} />}
                    content={
                      <TouchableOpacity activeOpacity={1} onPress={noop}>
                        <PatientSectionNotifications patientID={patientID} />
                      </TouchableOpacity>
                    }
                  />
                  <Accordion
                    heading={<Heading text="Emails" level={2} />}
                    content={
                      <TouchableOpacity activeOpacity={1} onPress={noop}>
                        <PatientSectionEmails patientID={patientID} />
                      </TouchableOpacity>
                    }
                  />
                  <Accordion
                    heading={<Heading text="Caring Contacts" level={2} />}
                    content={
                      <TouchableOpacity activeOpacity={1} onPress={noop}>
                        <PatientSectionCaringContacts patientID={patientID} />
                      </TouchableOpacity>
                    }
                  />
                  <Accordion
                    heading={<Heading text="Devices" level={2} />}
                    content={
                      <TouchableOpacity activeOpacity={1} onPress={noop}>
                        <PatientSectionDevices patientID={patientID} />
                      </TouchableOpacity>
                    }
                  />
                </View>
              </View>
            ) : null}
          </View>
        </View>
      </PlatformScreen>
    </View>
  ) : null;
}
