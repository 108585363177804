import { ComponentProps, PropsWithChildren } from 'react';

import { LinkButton } from '@oui/app-core/src/components/LinkButton';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { useTheme } from '@oui/app-core/src/styles';

import { Icon } from '@src/components/Icon';

type Crumb = {
  label: string;
  to: ComponentProps<typeof LinkButton>['to'];
  params?: ComponentProps<typeof LinkButton>['params'];
};

export function Breadcrumbs(props: PropsWithChildren<{ crumbs: Crumb[] }>) {
  const { width: _width } = useWindowDimensions();
  const { theme } = useTheme();

  return (
    <View
      row
      spacing={8}
      style={{
        borderBottomWidth: 1,
        borderBottomColor: theme.color.gray600,
        height: 75,
        justifyContent: 'space-between',
        paddingHorizontal: 20,
      }}
    >
      {props.crumbs?.map((crumb, i, arr) => {
        const isLast = i === arr.length - 1;
        const label = (
          <Text
            text={crumb.label}
            size={21}
            color={isLast ? undefined : theme.color.gray400}
            key={crumb.to}
          />
        );
        return isLast ? (
          label
        ) : (
          <View row spacing={8} key={crumb.to}>
            <LinkButton to={crumb.to} params={crumb.params} disabled={!crumb.to}>
              {label}
            </LinkButton>
            <Icon name="caret-right" size={10} />
          </View>
        );
      })}
      <View flex={1} />
      {props.children}
    </View>
  );
}
