import { ScrollView } from 'react-native';

import { ChecklistWithPersistence } from '@oui/app-core/src/components/Checklist';

export function ClinicianMyPlanIntroduction() {
  return (
    <ScrollView>
      <ChecklistWithPersistence
        compositionTitle="MyPlanIntroductionChecklist"
        title="Introduce MyPlan"
        steps={[
          {
            ID: 'use',
            title: 'Use MyPlan when in a crisis',
            items: [],
          },
          {
            ID: 'gradual',
            title: 'MyPlan - as risk more severe, solutions more serious',
            items: [],
          },
          {
            ID: 'skip',
            title: 'Can skip steps in MyPlan',
            items: [],
          },
          {
            ID: 'most-helpful',
            title: 'Use the step you think is most helpful',
            items: [],
          },
          {
            ID: 'videos',
            title: 'Learn Voiceover & Vignette videos available',
            items: [],
          },
        ]}
      />
    </ScrollView>
  );
}
