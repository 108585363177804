import noop from 'lodash/noop';
import { useEffect, useMemo, useState } from 'react';
import { Clipboard, TouchableOpacity } from 'react-native';

import { Accordion } from '@oui/app-core/src/components/Accordion';
import { Button } from '@oui/app-core/src/components/Button';
import { Icon } from '@oui/app-core/src/components/Icon';
import { PickerInput } from '@oui/app-core/src/components/PickerInput';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { PatientSupporterInput, PatientSupporterRelation } from '@oui/lib/src/types';
import { GQLUUID } from '@oui/lib/src/types/scalars';
import { ContactImage } from '@oui/myplan/src/components/Contact';

import { EmailInput, TextInput } from '@src/components/TextInput';
import { usePatient } from '@src/hooks/usePatient';

import {
  useAddPatientSupporterMutation,
  useIssueTokenLinkForSupporterMutation,
} from './PatientSupportInvitationForm.graphql.generated';

const CopyInvitationToken = ({ invitationToken }: { invitationToken: string }) => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const [invitationLinkCopied, setInvitationLinkCopied] = useState(false);
  return (
    <View spacing={5}>
      <Text testID="CopyInvitationToken_token" text={invitationToken} />
      <View row spacing={8}>
        <Button
          testID="CopyInvitationToken_copy"
          variant="text"
          onPress={() => {
            Clipboard.setString(invitationToken);
            setInvitationLinkCopied(true);
          }}
          text={$t({
            id: 'CopyInvitationToken_copy',
            defaultMessage: 'Copy',
          })}
          textStyle={{ fontSize: 15 }}
          style={{ paddingVertical: 0 }}
        />
        {invitationLinkCopied ? (
          <View row spacing={5}>
            <Icon name="check" color={theme.color.success} />
            <Text
              text={$t({
                id: 'CopyInvitationToken_clipboard',
                defaultMessage: 'Copied to clipboard',
              })}
              color={theme.color.success}
              size={15}
              weight="semibold"
            />
          </View>
        ) : null}
      </View>
    </View>
  );
};

function PatientSupporterInvitationForm({
  patientID,
  organizationID,
}: {
  patientID: GQLUUID;
  organizationID: GQLUUID;
}) {
  const { $t } = useI18n();
  const { refetch } = usePatient(patientID);
  const [addPatientSupporterMutation, { loading, data: addPatientSupporterData }] =
    useAddPatientSupporterMutation();
  const [issueTokenLinkForSupporterMutation, { data: issueTokenLinkForSupporterData }] =
    useIssueTokenLinkForSupporterMutation();

  const { validate, bind, data } = useForm<{
    supporter: PatientSupporterInput;
  }>({});

  const submit = () => {
    if (validate()) {
      return addPatientSupporterMutation({
        variables: {
          organizationID: organizationID,
          patientID: patientID,
          supporter: {
            person: {
              email: data.supporter.person.email,
              familyName: data.supporter.person.familyName,
              givenName: data.supporter.person.givenName,
            },
            relation: data.supporter.relation,
          },
        },
      });
    }

    return;
  };

  useEffect(() => {
    if (addPatientSupporterData?.addPatientSupporter?.supporterID) {
      issueTokenLinkForSupporterMutation({
        variables: {
          supporterID: addPatientSupporterData.addPatientSupporter.supporterID,
        },
      });
      refetch();
    }
  }, [addPatientSupporterData, issueTokenLinkForSupporterMutation, refetch]);

  const invitationToken = issueTokenLinkForSupporterData?.issueTokenLinkForSupporter?.url;

  return (
    <View style={{ backgroundColor: 'white', padding: 32, borderRadius: 16 }} spacing={32}>
      <View spacing={10}>
        <Heading
          level={3}
          text={$t({
            id: 'PatientSupporterInvitationForm_title_step1',
            defaultMessage: '1. Enter caregiver info',
          })}
        />
        <View spacing={5}>
          <TextInput
            {...bind(['supporter', 'person', 'givenName'], {
              label: 'First name*',
              validator: [{ type: 'present' }],
            })}
            placeholder={$t({
              id: 'PatientSupporterInvitationForm_firstName_placeholder',
              defaultMessage: 'Smith',
            })}
          />
          <TextInput
            {...bind(['supporter', 'person', 'familyName'], {
              label: 'Last name*',
              validator: [{ type: 'present' }],
            })}
            placeholder={$t({
              id: 'PatientSupporterInvitationForm_familyName_placeholder',
              defaultMessage: 'Smith',
            })}
          />

          <EmailInput
            {...bind(['supporter', 'person', 'email'], {
              label: 'Email*',
              validator: { type: 'email' },
            })}
          />

          <PickerInput
            {...bind(['supporter', 'relation'], {
              label: 'Relation*',
              validator: [{ type: 'present' }],
            })}
            placeholder={$t({
              id: 'PatientSupporterInvitationForm_relation_placeholder',
              defaultMessage: 'Select relation',
            })}
            items={[
              {
                label: $t({
                  id: 'PatientSupporterInvitationForm_picker_clinician',
                  defaultMessage: 'Clinician',
                }),
                value: PatientSupporterRelation.CLINICIAN,
              },
              {
                label: $t({
                  id: 'PatientSupporterInvitationForm_picker_parentGuardian',
                  defaultMessage: 'Parent/Guardian',
                }),
                value: PatientSupporterRelation.PARENT_GUARDIAN,
              },
              {
                label: $t({
                  id: 'PatientSupporterInvitationForm_picker_relative',
                  defaultMessage: 'Relative',
                }),
                value: PatientSupporterRelation.RELATIVE,
              },
              {
                label: $t({
                  id: 'PatientSupporterInvitationForm_picker_socialServices',
                  defaultMessage: 'Social services',
                }),
                value: PatientSupporterRelation.SOCIAL_SERVICES,
              },
              {
                label: $t({
                  id: 'PatientSupporterInvitationForm_picker_other',
                  defaultMessage: 'Other',
                }),
                value: PatientSupporterRelation.OTHER,
              },
            ]}
            testID="PatientSupporterInvitationForm_relationInput"
          />
        </View>
      </View>
      <View spacing={10}>
        <Heading
          level={3}
          text={$t({
            id: 'PatientSupporterInvitationForm_title_step2',
            defaultMessage: '2. Give caregiver app download link',
          })}
        />
        <Text text="avivacaregiver.app" />
      </View>
      <View spacing={5}>
        <Heading
          level={3}
          text={$t({
            id: 'PatientSupporterInvitationForm_title_step3',
            defaultMessage: '3. Generate and email claim link',
          })}
        />
        {invitationToken ? (
          <CopyInvitationToken invitationToken={invitationToken} />
        ) : (
          <Button
            testID="PatientSupporterInvitationForm_submit"
            alignSelf="flex-start"
            variant="solid"
            style={{ marginTop: 12 }}
            disabled={loading}
            text={$t({
              id: 'PatientSupporterInvitationForm_submit',
              defaultMessage: 'Generate link',
            })}
            pressOnce
            onPress={submit}
          />
        )}
      </View>
    </View>
  );
}

function ConnectedPatientSupporter({ patientID }: { patientID: GQLUUID }) {
  const { $t, formatDate } = useI18n();
  const { data: patientData, loading: patientDataLoading, refetch } = usePatient(patientID);

  const [
    issueInvitationLink,
    { loading: issuingInvitationLink, data: issueTokenLinkForSupporterData },
  ] = useIssueTokenLinkForSupporterMutation();

  const patientSupporter = useMemo(() => {
    return patientData?.patientByPatientID?.supporters.filter(
      (supporter) => supporter.claimed,
    )?.[0];
  }, [patientData]);

  useEffect(() => {
    if (issueTokenLinkForSupporterData) {
      refetch();
    }
  }, [issueTokenLinkForSupporterData, refetch]);

  const submit = () => {
    return issueInvitationLink({
      variables: {
        supporterID: patientSupporter!.supporterID,
      },
    });
  };

  if (patientDataLoading || !patientSupporter?.claimed) {
    return null;
  }

  const supporterFullName = `${patientSupporter.supporter.person.givenName} ${patientSupporter.supporter.person.familyName}`;

  const longDate = formatDate(patientSupporter.supporter.createdAt, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    minute: '2-digit',
    hourCycle: 'h12',
  });

  const invitationToken = issueTokenLinkForSupporterData?.issueTokenLinkForSupporter?.url;

  return (
    <View
      testID="ConnectedPatientSupporter"
      row
      style={{ backgroundColor: 'white', padding: 32, borderRadius: 16 }}
      spacing={32}
    >
      <View spacing={16} flex={1}>
        <View
          row
          style={{
            justifyContent: 'space-between',
          }}
        >
          <View>
            <View row spacing={10}>
              <ContactImage nameStr={supporterFullName} imageSize={25} inverted />
              <Text
                text={$t(
                  {
                    id: 'ConnectedPatientSupporter_connected',
                    defaultMessage: '{supporterFullName} connected',
                  },
                  { supporterFullName },
                )}
              />
            </View>

            <Text
              text={longDate}
              style={{
                marginLeft: 35,
              }}
            />
          </View>
          {!invitationToken ? (
            <Button
              testID="ConnectedPatientSupporter_reset"
              alignSelf="flex-start"
              variant="text"
              style={{ marginTop: 12 }}
              disabled={issuingInvitationLink}
              text={$t({
                id: 'ConnectedPatientSupporter_submit',
                defaultMessage: 'Reset',
              })}
              pressOnce
              onPress={submit}
            />
          ) : null}
        </View>

        {invitationToken ? <CopyInvitationToken invitationToken={invitationToken} /> : null}
      </View>
    </View>
  );
}

export function PatientSupportInvitationForm({ patientID }: { patientID: GQLUUID }) {
  const { $t } = useI18n();
  const { data: currentUserData, loading: currentUserDataLoading } = useCurrentUser();
  const { data: patientData, loading: patientDataLoading } = usePatient(patientID);

  const supporter = useMemo(() => {
    return patientData?.patientByPatientID?.supporters.filter(
      (supporter) => supporter.claimed,
    )?.[0];
  }, [patientData]);

  const organizationID = currentUserData?.currentUser?.primaryOrganization?.ID as GQLUUID;

  if (patientData?.patientByPatientID?.patient.productVariant !== 'AVIVA_ADOLESCENT') {
    return null;
  }

  if (!organizationID) {
    return null;
  }

  if (currentUserDataLoading || patientDataLoading) {
    return null;
  }

  return (
    <Accordion
      heading={
        <Heading
          testID="PatientSupportInvitationForm_title"
          text={$t({
            id: 'PatientSupportInvitationForm_title',
            defaultMessage: 'Caregiver app',
          })}
          level={2}
        />
      }
      content={
        <TouchableOpacity activeOpacity={1} onPress={noop}>
          {supporter ? (
            <ConnectedPatientSupporter patientID={patientID} />
          ) : (
            <PatientSupporterInvitationForm organizationID={organizationID} patientID={patientID} />
          )}
        </TouchableOpacity>
      }
    />
  );
}
