import { ComponentProps, useEffect, useState } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { RadioInput } from '@oui/app-core/src/components/RadioInput';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useTheme } from '@oui/app-core/src/styles';
import {
  OnboardingVariant,
  OuiUserRoleType,
  ProductVariant,
  ProductVersion,
} from '@oui/lib/src/types/graphql.generated';

import PickerInput from '@src/components/PickerInput';
import { PlatformScreen } from '@src/components/PlatformScreen';
import { ProductVariantsInput } from '@src/components/ProductVariantsInput';
import TextInput, { EmailInput } from '@src/components/TextInput';
import { useAddOrganizationMemberMutation } from '@src/screens/Organization.graphql.generated';
import { useOrganizationsListQuery } from '@src/screens/Organizations.graphql.generated';
import { CliniciansScreenProps } from '@src/types';

import {
  AddOrganizationMutationVariables,
  useAddOrganizationMutation,
} from './NewOrganization.graphql.generated';

function Subheading(props: ComponentProps<typeof Text>) {
  const { theme } = useTheme();
  return <Text {...props} size={17} color={theme.color.dark} weight="bold" />;
}

export function NewOrganization(props: CliniciansScreenProps<'NewOrganization'>) {
  const { theme } = useTheme();
  const [addOrganization, { error: addOrganizationError }] = useAddOrganizationMutation();
  const { data: organizationsData } = useOrganizationsListQuery({
    variables: { parentOnly: true },
  });

  const [addRegistrar, { error: addRegistrarError }] = useAddOrganizationMemberMutation();
  const {
    validate: validateOrganization,
    bind,
    data: organizationData,
    humanErrors,
  } = useForm<AddOrganizationMutationVariables['organization']>(
    {
      productVersion: ProductVersion.AVIVA_2_0,
      productVariants: [ProductVariant.AVIVA_ADULT],
      onboardingVariant: OnboardingVariant.COLLABORATIVE,
      name: '',
      contactPoint: [],
      address: [],
      caringContactsEnabled: true,
    },
    {
      merger: (originalValue: any, formValue: any) => {
        if (Array.isArray(originalValue)) {
          return formValue;
        }
      },
    },
  );
  const {
    validate: validateRegistrar,
    bind: bindRegistrar,
    data: registrarData,
    humanErrors: registrarHumanErrors,
  } = useForm<{ person: { givenName: string; familyName: string; email: string } }>({});
  const [isParentOrg, setIsParentOrg] = useState<boolean | null>(null);

  useEffect(() => {
    if (isParentOrg) {
      bind('parent', { label: '' }).onChangeValue(undefined);
    }
    // eslint-disable-next-line
  }, [isParentOrg]);

  return (
    <PlatformScreen
      crumbs={[
        { label: 'Organizations', to: 'organizations' },
        { label: 'Add new Organization', to: '' },
      ]}
      testID="NewOrganization_scroll"
    >
      <View spacing={30}>
        <Heading text="Add new organization" level={2} />
        <View
          spacing={50}
          style={{
            backgroundColor: 'white',
            paddingHorizontal: 25,
            paddingVertical: 20,
            borderRadius: 10,
            maxWidth: 600,
            flex: 1,
          }}
        >
          {addOrganizationError ||
          addRegistrarError ||
          Object.keys(humanErrors).length ||
          Object.keys(registrarHumanErrors).length ? (
            <ErrorPresenter
              error={addOrganizationError || addRegistrarError}
              formErrors={{ ...humanErrors, ...registrarHumanErrors }}
            />
          ) : null}
          <View spacing={20}>
            <Subheading text="Organization" />
            <TextInput
              {...bind('name', { validator: { type: 'present' }, label: 'Organization name*' })}
              placeholder="Hospital site"
            />
            <RadioInput
              horizontal
              value={isParentOrg}
              onChangeValue={setIsParentOrg}
              optionLabelWeight="normal"
              innerStyle={{
                height: undefined,
                alignItems: 'flex-start',
              }}
              items={[
                {
                  label: 'Parent organization',
                  value: true,
                  style: { flex: 1, padding: 10, marginHorizontal: -10 },
                },
                {
                  label: 'Sub-organization',
                  value: false,
                  style: { flex: 1, padding: 10, marginHorizontal: -10 },
                  selectedStyle: {
                    backgroundColor: theme.color.gray800,
                    padding: 10,
                    borderRadius: 10,
                  },
                  selectedChild: !isParentOrg ? (
                    <View>
                      <PickerInput
                        {...bind('parent', { label: '', validator: { type: 'present' } })}
                        placeholder="Select parent organization"
                        items={
                          organizationsData?.organizations?.map((o) => ({
                            label: o.name,
                            value: o.ID,
                          })) ?? []
                        }
                      />
                    </View>
                  ) : null,
                },
              ]}
              label="Organization type*"
            />
          </View>
          <View spacing={20}>
            <Subheading text="Admin" />
            <View row spacing={20} childFlex={1}>
              <TextInput
                {...bindRegistrar(['person', 'givenName'], {
                  validator: { type: 'present' },
                  label: 'First name*',
                })}
                placeholder="Christina"
              />
              <TextInput
                {...bindRegistrar(['person', 'familyName'], {
                  validator: { type: 'present' },
                  label: 'Last name*',
                })}
                placeholder="Smith"
              />
            </View>
            <EmailInput
              {...bindRegistrar(['person', 'email'], {
                validator: { type: 'email' },
                label: 'Email*',
              })}
              placeholder="email@domain.com"
            />
          </View>
          <View spacing={20}>
            <Subheading text="Products" />
            <Text text="Intervention" weight="semibold" />
            <ProductVariantsInput
              value={organizationData.productVariants!}
              onChangeValue={bind('productVariants', { label: '' }).onChangeValue}
              caringContactsEnabled={organizationData.caringContactsEnabled!}
              onChangeCaringContactsEnabled={
                bind('caringContactsEnabled', { label: '' }).onChangeValue
              }
              onboardingVariant={organizationData.onboardingVariant!}
              onChangeOnboardingVariant={bind('onboardingVariant', { label: '' }).onChangeValue}
            />
          </View>
          <Button
            alignSelf="center"
            style={{ marginTop: 12 }}
            text="Create organization"
            onPress={async () => {
              const isOrgValid = validateOrganization();
              const isRegistrarValid = validateRegistrar();
              if (isOrgValid && isRegistrarValid) {
                const result = await addOrganization({
                  variables: {
                    organization: organizationData,
                  },
                });
                const organizationID = result?.data?.addOrganization?.ID;
                if (organizationID) {
                  const registrarResult = await addRegistrar({
                    variables: {
                      organizationID,
                      person: registrarData.person,
                      roles: [OuiUserRoleType.REGISTRAR],
                    },
                  });
                  if (registrarResult.data?.addOrganizationMember?.member.userID) {
                    props.navigation.replace('Organization', { organizationID });
                  }
                }
              }
            }}
          />
        </View>
      </View>
    </PlatformScreen>
  );
}
