import { useQuery } from '@apollo/client';
import { StackActions, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { useDebounce } from 'use-debounce';

import { useAppContext } from '@oui/app-core/src/components/AppContext';
import { Button } from '@oui/app-core/src/components/Button';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  CurrentUserOrganizationsQuery,
  useOrganizations,
} from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { formatRelativeTime } from '@oui/lib/src/formatRelativeTime';
import { OnboardingVariant, ProductVersion } from '@oui/lib/src/types/graphql.generated';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { Icon } from '@src/components/Icon';
import { getSessionsForProductVersion } from '@src/components/PatientSectionAvivaProgress';
import PickerInput from '@src/components/PickerInput';
import { PlatformScreen } from '@src/components/PlatformScreen';
import { StaffTable, TableCell } from '@src/components/StaffTable';
import TextInput from '@src/components/TextInput';
import { usePatientsListQuery } from '@src/screens/Patients.graphql.generated';
import { CliniciansScreenProps } from '@src/types';

type Props = CliniciansScreenProps<'Patients'>;

export function PatientsV2(props: Props) {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 100);
  const withOrganizationID = props.route.params?.organizationID;
  const { data, loading, refetch } = usePatientsListQuery({
    variables: {
      searchPattern: debouncedSearch,
      withOrganizationID,
    },
  });
  const { locale } = useAppContext();
  const { $t } = useI18n();
  const { data: organizations } = useQuery(CurrentUserOrganizationsQuery, {
    fetchPolicy: 'cache-only',
  });
  const selectedOrganization = useMemo(() => {
    if (withOrganizationID) {
      return organizations?.organizations?.find((o) => o.ID === withOrganizationID);
    }
    return null;
  }, [organizations, withOrganizationID]);

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return (
    <PlatformScreen
      crumbs={[{ label: 'Patients', to: 'patients' }]}
      testID="Patients"
      disableScroll
    >
      <View
        row
        style={{
          justifyContent: 'space-between',
          paddingHorizontal: 12,
          marginBottom: 12,
        }}
      >
        <Heading
          text={selectedOrganization ? `Patients in ${selectedOrganization.name}` : 'Patients'}
          level={1}
        />
        <OrganizationPicker
          label="Organization"
          labelHorizontal="small"
          placeholder="All organizations"
        />
      </View>
      <View
        row
        style={{
          justifyContent: 'space-between',
          paddingHorizontal: 12,
          padding: 10,
          marginBottom: 25,
          backgroundColor: 'white',
          borderRadius: 8,
        }}
      >
        <TextInput
          placeholder="Search"
          value={search}
          onChangeValue={setSearch}
          style={{ minWidth: 300 }}
        />
        <Button
          icon="plus"
          onPress={() =>
            props.navigation.push('NewPatient', {
              organizationID: props.route.params?.organizationID,
            })
          }
          variant="solid"
          text="Add patient"
          testID="Patients_addNew"
        />
      </View>
      <StaffTable
        columns={['Name', 'Primary Clinician', 'Session status', 'Last login']}
        loading={loading}
        testID="Patients_list"
        navigationExtractor={(item) => ({
          to: 'patients/:patientID',
          params: { patientID: item.patient.ID },
          action: StackActions.push('Patient', { patientID: item.patient.ID }),
        })}
        renderItem={({ item }) => {
          // This can happen due to server error
          if (!item) return null;
          const fullName = `${item.patient.person.givenName} ${item.patient.person.familyName}`;
          const lastProgressItem = item.progress.length
            ? item.progress[item.progress.length - 1]
            : undefined;
          const appSetup = item.lastLogin !== null;
          const primaryClinician = item.careTeam?.primaryPractitioner;
          const clinicianName = primaryClinician
            ? `${primaryClinician.person.givenName ?? ''} ${
                primaryClinician.person.familyName ?? ''
              }`
            : '';
          const sessions = getSessionsForProductVersion({
            $t,
            ...item.patient,
          });

          const lastProgressItemIndex =
            lastProgressItem &&
            sessions.findIndex(
              (s) =>
                s.contentType === lastProgressItem.content ||
                (s as any).alternateContentType === lastProgressItem.content, // eslint-disable-line
            );

          return (
            <>
              <TableCell>
                <Text text={fullName} weight="semibold" />
              </TableCell>
              <TableCell>
                <Text text={clinicianName} />
              </TableCell>
              <TableCell>
                {lastProgressItem ? (
                  <View row>
                    <View style={{ marginRight: 4 }}>
                      {lastProgressItem.completed ? (
                        <Icon color="green" name="check" size={24} />
                      ) : (
                        <Icon color="#ecbf2d" name="in-progress" style={{ marginRight: 4 }} />
                      )}
                    </View>
                    {lastProgressItem.completed ? (
                      <Text text="Completed" />
                    ) : (
                      <Text text="Started" />
                    )}
                    <Text
                      text={` ${
                        typeof lastProgressItemIndex !== 'number' || lastProgressItemIndex === -1
                          ? lastProgressItem.content
                          : lastProgressItemIndex + 1
                      } on ${new Date(lastProgressItem.updatedAt).toLocaleDateString(locale, {
                        month: 'short',
                        day: 'numeric',
                      })}`}
                    />
                  </View>
                ) : appSetup &&
                  item.patient.productVersion === ProductVersion.AVIVA_2_0 &&
                  item.patient.onboardingVariant === OnboardingVariant.COLLABORATIVE ? (
                  <Button
                    style={{ paddingVertical: 0 }}
                    variant="text"
                    text="Start Session 1"
                    onPress={() => {
                      // Need to push Patient onto stack so ClinicianLock can pop back to it later
                      props.navigation.navigate('Patient', { patientID: item.patient.ID });
                      props.navigation.navigate('MyStoryMyPlan', {
                        screen: 'MyStoryMyPlanOverview',
                        params: { patientID: item.patient.ID },
                      });
                    }}
                    size="small"
                  />
                ) : null}
              </TableCell>
              <TableCell>
                {!appSetup ? (
                  <Text text="Never" />
                ) : item.lastLogin ? (
                  <Text text={formatRelativeTime(locale, new Date(item.lastLogin))} />
                ) : null}
              </TableCell>
            </>
          );
        }}
        data={loading ? [] : data?.patients}
        keyExtractor={(patient, i) => patient?.patient?.ID ?? i}
      />
    </PlatformScreen>
  );
}

export const HeaderButton = ({
  icon,
  onPress,
  color,
}: {
  icon: ComponentProps<typeof Icon>['name'];
  onPress: () => void;
  color?: string;
}) => {
  const { theme } = useTheme();
  return (
    <TouchableOpacity
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        aspectRatio: 1,
      }}
      onPress={onPress}
    >
      <Icon name={icon} color={color || theme.color.primary100} size={24} />
    </TouchableOpacity>
  );
};

export function OrganizationPicker({
  label,
  labelHorizontal,
  placeholder,
}: {
  label?: string;
  labelHorizontal?: boolean | 'small';
  placeholder?: string;
}) {
  const navigation = useNavigation<Props['navigation']>();
  const route = useRoute<Props['route']>();
  const { data } = useOrganizations();
  const onSelectOrganizationID = (organizationID: GQLUUID) => {
    navigation.setParams({ organizationID });
  };
  const items =
    data?.organizations?.map((org) => ({
      value: org.ID,
      label: org.name ?? 'Unknown',
    })) ?? [];

  return data?.organizations && data.organizations.length > 1 ? (
    <PickerInput
      label={label}
      labelHorizontal={labelHorizontal}
      placeholder={placeholder}
      items={
        label
          ? items
          : [{ value: undefined, label: 'All organizations' }, ...items].sort((a, b) => {
              if (a.label === b.label) return 0;
              return a.label < b.label ? -1 : 1;
            })
      }
      onChangeValue={onSelectOrganizationID}
      value={route.params?.organizationID}
    />
  ) : null;
}
