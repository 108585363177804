'use client';

import { produce } from 'immer';
import { ComponentProps } from 'react';
import { Pressable } from 'react-native';

import { Accordion } from '../components/Accordion';
import { Checkbox } from '../components/Checkbox';
import { Icon } from '../components/Icon';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useChecklist } from '../hooks/useComposition';
import { useTheme } from '../styles';

type ChecklistStepItemProps = {
  icon: ComponentProps<typeof Icon>['name'];
  title: string;
  content: string[];
};

function ChecklistStepItem(props: ChecklistStepItemProps) {
  const { theme } = useTheme();
  return (
    <Accordion
      heading={
        <View row spacing={12}>
          <Icon name={props.icon || 'event'} color={theme.color.gray400} />
          <Text text={props.title} color={theme.color.gray400} />
        </View>
      }
      content={props.content.map((c) => {
        return <Text key={c} text={c} />;
      })}
    />
  );
}

type ChecklistStepProps = {
  ID: string;
  title: string;
  items: Array<ChecklistStepItemProps>;
};

const LINE_HEIGHT = 30;
function ChecklistStep(props: ChecklistStepProps & { checked: boolean; onToggleStep: () => void }) {
  const checked = props.checked;
  const setChecked = () => props.onToggleStep();
  return (
    <View row style={{ alignItems: 'flex-start' }} spacing={12}>
      <View style={{ height: LINE_HEIGHT, justifyContent: 'center' }}>
        <Checkbox value={checked} onChangeValue={setChecked} />
      </View>
      <View flex={1} spacing={10} style={{ opacity: checked ? 0.6 : 1 }}>
        <Pressable onPress={props.onToggleStep}>
          <Text
            text={props.title}
            size={17}
            weight="semibold"
            style={{ lineHeight: LINE_HEIGHT }}
            strikethrough={checked}
          />
        </Pressable>
        {props.items.map((item) => (
          <ChecklistStepItem {...item} key={item.title} />
        ))}
      </View>
    </View>
  );
}

type ChecklistProps = {
  title: string;
  steps: ChecklistStepProps[];
};

function Checklist(
  props: ChecklistProps & {
    checkedSteps?: string[];
    onToggleStep: (ID: string) => void;
  },
) {
  return (
    <View style={{ paddingHorizontal: 32, paddingVertical: 20 }} spacing={18}>
      <Text text={props.title} weight="semibold" size={21} />
      {props.steps.map((step) => (
        <ChecklistStep
          {...step}
          key={step.ID}
          checked={!!props.checkedSteps?.includes(step.ID)}
          onToggleStep={() => props.onToggleStep(step.ID)}
        />
      ))}
    </View>
  );
}

export function ChecklistWithPersistence(props: ChecklistProps & { compositionTitle: string }) {
  const { data, update } = useChecklist(props.compositionTitle);

  return (
    <Checklist
      {...props}
      onToggleStep={(key) => {
        const checklist = data?.json.checklist ?? [];
        const foundChecklistItemIndex = checklist.findIndex((i) => i.key === key);
        const newDoc = produce(checklist, (draft) => {
          if (foundChecklistItemIndex !== -1) {
            draft[foundChecklistItemIndex].completed = !draft[foundChecklistItemIndex].completed;
          } else {
            draft.push({ key, completed: true });
          }
        });
        void update({ checklist: newDoc });
      }}
      checkedSteps={data?.json.checklist?.filter((i) => i.completed).map((i) => i.key!)}
    />
  );
}
