import equals from 'fast-deep-equal';
import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { v4 as uuid } from 'uuid';

import { Button } from '@oui/app-core/src/components/Button';
import { ListItemTextInput } from '@oui/app-core/src/components/ListItemTextInput';
import { PhoneInput } from '@oui/app-core/src/components/PhoneInput';
import { PlacesTypeahead } from '@oui/app-core/src/components/PlacesTypeahead';
import { SwitchInput } from '@oui/app-core/src/components/SwitchInput';
import { Text } from '@oui/app-core/src/components/Text';
import { UnorderedList } from '@oui/app-core/src/components/UnorderedList';
import { View } from '@oui/app-core/src/components/View';
import { MyStoryMyPlanCompositionDataHash } from '@oui/app-core/src/hooks/useComposition';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useTheme } from '@oui/app-core/src/styles';
import { MyStoryMyPlanContact } from '@oui/lib/src/transformExpoContactToResponseContact';
import { BLANK_LOCATION, PROFESSIONAL_SERVICES } from '@oui/myplan/src/components/PatientMyPlan';

import { ContactListItem } from '@src/components/Contact';
import Divider from '@src/components/Divider';
import { Icon } from '@src/components/Icon';
import { TextInput } from '@src/components/TextInput';
import Sentry from '@src/sentry';

function Step(props: {
  testID: string;
  num: number | null;
  title: string;
  description?: string;
  editChildren: ReactNode;
  viewChildren: ReactNode;
}) {
  const { theme } = useTheme();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <View spacing={10} testID={props.testID}>
      <View row style={{ justifyContent: 'space-between' }}>
        <View row spacing={8}>
          {props.num === null ? null : <Text text={`Step ${props.num}`} />}
          <Text text={props.title} weight="semibold" size={17} />
        </View>
        {isEditing ? (
          <Button
            text="Done"
            onPress={() => setIsEditing(false)}
            testID={`${props.testID}_doneButton`}
          />
        ) : (
          <Button
            text="Edit"
            variant="text"
            onPress={() => setIsEditing(true)}
            testID={`${props.testID}_editButton`}
          />
        )}
      </View>
      {isEditing && props.description ? (
        <Text text={props.description} color={theme.color.gray300} />
      ) : null}
      {isEditing ? props.editChildren : props.viewChildren}
    </View>
  );
}

function ContactPicker({
  isPatientContactPickerActive,
  onChangeValue,
  onTogglePatientContactPicker,
  showHeader = true,
  value,
}: {
  isPatientContactPickerActive: boolean;
  onChangeValue: (newValue: MyStoryMyPlanContact[]) => unknown;
  onTogglePatientContactPicker: () => Promise<unknown>;
  showHeader?: boolean;
  value: ReadonlyArray<MyStoryMyPlanContact>;
}) {
  const { theme } = useTheme();
  const [isNarrow, setIsNarrow] = useState(false);
  const { validate, clear, data, bind } = useForm({ firstName: '', phone: '' });

  function addManualContact() {
    if (validate()) {
      onChangeValue([
        ...value,
        {
          ...data,
          ID: uuid(),
          lastName: '',
          localDeviceID: '',
          imageBase64: '',
        },
      ]);
      clear();
    }
  }

  function removeContact(ID: string) {
    onChangeValue(value.filter((v) => v.ID !== ID));
  }

  return (
    <View
      flex={1}
      style={{
        borderRadius: 10,
        borderWidth: 1,
        borderColor: theme.color.gray600,
        maxWidth: 500,
      }}
      onLayout={(e) => {
        const newIsNarrow = e.nativeEvent.layout.width < 400;
        if (newIsNarrow !== isNarrow) setIsNarrow(newIsNarrow);
      }}
      testID="ContactPicker"
    >
      <View style={{ padding: 10 }} spacing={12}>
        {showHeader ? (
          <View row spacing={8}>
            <Icon name="people" />
            <Text text="People that provide distraction" weight="semibold" />
          </View>
        ) : null}
        {value.length ? (
          value.map((contact) => (
            <View row key={contact.ID}>
              <View flex={1}>
                <ContactListItem
                  imageSize={40}
                  imageBorderRadius
                  nameWeight="normal"
                  name={{ first: contact.firstName, last: contact.lastName }}
                />
              </View>
              <Icon
                aria-label="Remove contact"
                size={14}
                name="close"
                onPress={() => removeContact(contact.ID)}
                color={theme.color.gray400}
              />
            </View>
          ))
        ) : (
          <View row spacing={12}>
            <View
              row
              style={{
                borderRadius: 40,
                backgroundColor: theme.color.gray700,
                padding: 10,
              }}
            >
              <Icon name="plus" color={theme.color.gray500} />
            </View>
            <Text text="Add a contact" color={theme.color.gray500} />
          </View>
        )}
      </View>
      <View
        style={{
          padding: 10,
          backgroundColor: theme.color.gray800,
          borderTopWidth: 1,
          borderColor: theme.color.gray700,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
        }}
        spacing={16}
      >
        <Text text="Allow the patient to use their phone contacts to add people themselves." />
        <View row style={{ justifyContent: 'space-between' }}>
          <Text text="Contact picker" weight="semibold" />
          <SwitchInput
            showOnOff
            value={isPatientContactPickerActive}
            onChangeValue={() => {
              onTogglePatientContactPicker();
            }}
          />
        </View>
        <Divider style={{ marginVertical: 0 }} />
        <Text text="Or add manually" textAlign="center" />
        <View row={!isNarrow} spacing={isNarrow ? 0 : 10}>
          <TextInput
            placeholder="Full name"
            {...bind('firstName', {
              'aria-label': 'Full name',
              validator: { type: 'present' },
            })}
            onSubmitEditing={addManualContact}
            style={{ flex: 1 }}
          />
          <View row spacing={10}>
            <PhoneInput
              placeholder="Phone number"
              {...bind('phone', { 'aria-label': 'Phone number' })}
              onSubmitEditing={addManualContact}
              style={{ flex: 1 }}
            />
            <Button
              testID="ContactPicker_submitButton"
              aria-label="Submit"
              icon="arrow-up"
              onPress={addManualContact}
              color={data.firstName ? undefined : theme.color.gray400}
              iconSize={30}
              disabled={!data.firstName.length}
              style={[
                data.firstName
                  ? null
                  : {
                      borderColor: theme.color.gray400,
                    },
              ]}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

function LocationPicker({
  value,
  onChangeValue,
  onTogglePatientPlacePicker,
  isPatientPlacePickerActive,
}: {
  value: MyStoryMyPlanCompositionDataHash['SOCIAL_DISTRACTIONS']['places'];
  onChangeValue: (
    newValue: MyStoryMyPlanCompositionDataHash['SOCIAL_DISTRACTIONS']['places'],
  ) => unknown;
  onTogglePatientPlacePicker: () => Promise<unknown>;
  isPatientPlacePickerActive: boolean;
}) {
  const { theme } = useTheme();
  const [fullname, setFullname] = useState('');

  function addManualPlace(place: string) {
    onChangeValue([
      ...value,
      {
        name: place,
        ID: uuid(),
        latitude: 0,
        longitude: 0,
      },
    ]);
  }

  function removePlace(ID: string) {
    onChangeValue(value.filter((v) => v.ID !== ID));
  }

  return (
    <View
      flex={1}
      style={{ borderRadius: 10, borderWidth: 1, borderColor: theme.color.gray600 }}
      testID="LocationPicker"
    >
      <View style={{ padding: 10 }} spacing={12}>
        <View row spacing={8}>
          <Icon name="pin" />
          <Text text="Places that provide distraction" weight="semibold" />
        </View>
        {value.length ? (
          value.map((contact) => (
            <View row key={contact.ID}>
              <View flex={1}>
                <ContactListItem
                  imageSize={40}
                  nameWeight="normal"
                  name={{ first: contact.name, last: '' }}
                />
              </View>
              <Icon
                aria-label="Remove place"
                size={14}
                name="close"
                onPress={() => removePlace(contact.ID)}
                color={theme.color.gray400}
              />
            </View>
          ))
        ) : (
          <View row spacing={12}>
            <View
              row
              style={{
                borderRadius: 10,
                backgroundColor: theme.color.gray700,
                padding: 10,
              }}
            >
              <Icon name="plus" color={theme.color.gray500} />
            </View>
            <Text text="Add a location" color={theme.color.gray500} />
          </View>
        )}
      </View>
      <View
        style={{
          padding: 10,
          backgroundColor: theme.color.gray800,
          borderTopWidth: 1,
          borderColor: theme.color.gray700,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
        }}
        spacing={16}
      >
        <Text text="Allow the patient to search for places on the map themselves." />
        <View row style={{ justifyContent: 'space-between' }}>
          <Text text="Map search" weight="semibold" />
          <SwitchInput
            showOnOff
            value={isPatientPlacePickerActive}
            onChangeValue={() => {
              onTogglePatientPlacePicker();
            }}
          />
        </View>
        <Divider style={{ marginVertical: 0 }} />
        <Text text="Or add manually" textAlign="center" />
        <View row>
          <PlacesTypeahead
            onSelect={(place) => {
              onChangeValue([...value, place]);
            }}
            style={{ flex: 1 }}
            inputStyle={{ paddingRight: 54 }}
            placeholder="Search for location"
          />
          <Button
            aria-label="submit"
            icon="arrow-up"
            onPress={() => {
              addManualPlace(fullname);
              setFullname('');
            }}
            color={fullname ? undefined : theme.color.gray400}
            iconSize={30}
            disabled={!fullname.length}
            style={[
              {
                position: 'absolute',
                right: 0,
              },
              fullname
                ? null
                : {
                    borderColor: theme.color.gray400,
                  },
            ]}
          />
        </View>
      </View>
    </View>
  );
}

export function ClinicianMyPlanForm({
  value,
  onChangeValue,
}: {
  value: MyStoryMyPlanCompositionDataHash;
  onChangeValue: (newData: MyStoryMyPlanCompositionDataHash) => Promise<unknown>;
}) {
  const { theme } = useTheme();

  const inflightChangesRef = useRef<Partial<typeof value>>({});
  const [dirty, onSetDirty] = useState<Partial<typeof value>>({});
  // our if inside useEffect addresses the every loop execution concerns
  // eslint-disable-next-line
  const data = { ...value, ...inflightChangesRef.current, ...dirty };
  const [debouncedValue] = useDebounce(data, 500, { maxWait: 10000, equalityFn: equals });
  const dirtyRef = useRef(dirty);
  dirtyRef.current = dirty;

  const saveDirtyChanges = useCallback(
    async (additionalChanges?: Partial<typeof value>) => {
      if (Object.keys(dirtyRef.current).length || additionalChanges) {
        const dirtyChanges = { ...dirtyRef.current, ...additionalChanges };
        inflightChangesRef.current = dirtyChanges;
        onSetDirty({});
        const finalData = { ...debouncedValue, ...additionalChanges };
        // Always ensure that this component forces us to be on MY_PLAN__REVIEW currentStep
        // otherwise we may have a race between the currentStep syncing logic in MyStoryMyPlanStack
        // and here
        finalData.APP_STATE = { ...finalData.APP_STATE, currentStep: 'MY_PLAN__REVIEW' };
        return onChangeValue(finalData)
          .then(() => {
            inflightChangesRef.current = {};
          })
          .catch((e) => {
            Sentry.withScope((scope) => {
              scope.setExtra('dirtyKeys', Object.keys(dirtyChanges));
              scope.setExtra('error', e.toString());
              Sentry.captureException('Failed to auto-save ClinicianMyPlanForm');
            });
          });
      }
    },
    [onChangeValue, debouncedValue],
  );

  const setEditor = useCallback(
    async (editor: MyStoryMyPlanCompositionDataHash['APP_STATE']['currentEditor']) => {
      await saveDirtyChanges({
        APP_STATE: { currentStep: 'MY_PLAN__REVIEW', currentEditor: editor },
      });
    },
    [saveDirtyChanges],
  );

  useEffect(() => {
    if (
      // Since currentStep can be slightly delayed due to how syncing works, we dont want to trigger
      // this auto-add functionality until we're fully synced
      data.APP_STATE.currentStep === 'MY_PLAN__REVIEW' &&
      !data.PROFESSIONAL_HELP_CONTACTS.find(
        (c) => c.contact.ID === 'national-suicide-prevention-lifeline',
      )
    ) {
      onSetDirty({
        PROFESSIONAL_HELP_CONTACTS: [
          ...data.PROFESSIONAL_HELP_CONTACTS,
          {
            contact: PROFESSIONAL_SERVICES[0],
            location: BLANK_LOCATION,
          },
        ],
      });
    }
  }, [data]);

  useEffect(() => {
    if (!equals(value, debouncedValue)) {
      saveDirtyChanges();
    }
  }, [value, debouncedValue, saveDirtyChanges]);

  const warningSigns = data.WARNING_SIGNS;
  const copingStrategies = data.COPING_STRATEGIES;

  return (
    <View style={{ padding: 24 }} spacing={25}>
      <View
        style={{
          borderRadius: 10,
          padding: 24,
          borderWidth: 1,
          borderColor: theme.color.gray600,
        }}
        spacing={46}
      >
        <View
          style={{
            paddingHorizontal: 24,
            paddingBottom: 10,
            marginVertical: -14,
            borderBottomWidth: 1,
            borderColor: theme.color.gray600,
            marginHorizontal: -24,
          }}
        >
          <Text text="Safety steps" color={theme.color.gray300} />
        </View>
        <Step
          testID="ClinicianMyPlanForm_step_warningSigns"
          num={1}
          title="Warning signs"
          description="Warning signs are thoughts, feelings, or behaviors that you have before a suicidal crisis escalates. These serve as a cue to use the MyPlan."
          viewChildren={
            <UnorderedList
              testID="ClinicianMyPlanForm_warningSigns"
              items={warningSigns.map((v) => v.text)}
              color={theme.color.primary100}
              weight="normal"
            />
          }
          editChildren={
            <ListItemTextInput
              testID="ClinicianMyPlanForm_warningSignsInput"
              aria-label="Warning signs"
              value={warningSigns}
              onChangeValue={(newWarningSigns) =>
                onSetDirty({ ...dirty, WARNING_SIGNS: newWarningSigns })
              }
              minHeight={100}
              bulletColor={theme.color.primary100}
            />
          }
        />
        <Step
          testID="ClinicianMyPlanForm_step_copingStrategies"
          num={2}
          title="Coping Strategies"
          description="These are skills and activities you can do alone that are helpful for coping during times of stress."
          viewChildren={
            <UnorderedList
              testID="ClinicianMyPlanForm_copingStrategies"
              items={copingStrategies.map((v) => v.text)}
              color={theme.color.accentTwo100}
              weight="normal"
            />
          }
          editChildren={
            <ListItemTextInput
              testID="ClinicianMyPlanForm_copingStrategiesInput"
              aria-label="Coping strategies"
              value={copingStrategies}
              onChangeValue={(newCopingStrategies) =>
                onSetDirty({ ...dirty, COPING_STRATEGIES: newCopingStrategies })
              }
              minHeight={100}
              bulletColor={theme.color.accentTwo100}
            />
          }
        />
        <Step
          testID="ClinicianMyPlanForm_step_socialDistractions"
          num={3}
          title="Social distractions"
          description="Social activities help you not think about your problems which allows time for the suicidal thoughts to subside. People in this step are not to be used to share or discuss a crisis."
          viewChildren={
            <View>
              {data.SOCIAL_DISTRACTIONS.contacts.map((contact) => (
                <Fragment key={contact.ID}>
                  <ContactListItem
                    imageSize={40}
                    imageBorderRadius
                    nameWeight="normal"
                    name={{ first: contact.firstName, last: contact.lastName }}
                  />
                  <Divider />
                </Fragment>
              ))}
              {data.SOCIAL_DISTRACTIONS.places.map((contact) => (
                <Fragment key={contact.ID}>
                  <ContactListItem
                    imageSize={40}
                    key={contact.ID}
                    name={{ first: contact.name, last: '' }}
                    nameWeight="normal"
                  />
                  <Divider />
                </Fragment>
              ))}
            </View>
          }
          editChildren={
            <View row spacing={25} style={{ alignItems: 'flex-start' }}>
              <ContactPicker
                value={data.SOCIAL_DISTRACTIONS.contacts}
                onChangeValue={(newContacts) => {
                  onSetDirty({
                    SOCIAL_DISTRACTIONS: { ...data.SOCIAL_DISTRACTIONS, contacts: newContacts },
                  });
                }}
                onTogglePatientContactPicker={async () => {
                  if (data.APP_STATE.currentEditor === 'PATIENT__SOCIAL_CONTACT_PICKER') {
                    await setEditor('CLINICIAN');
                  } else {
                    await setEditor('PATIENT__SOCIAL_CONTACT_PICKER');
                  }
                }}
                isPatientContactPickerActive={
                  data.APP_STATE.currentEditor === 'PATIENT__SOCIAL_CONTACT_PICKER'
                }
              />
              <LocationPicker
                value={data.SOCIAL_DISTRACTIONS.places}
                onChangeValue={(newPlaces) => {
                  onSetDirty({
                    SOCIAL_DISTRACTIONS: { ...data.SOCIAL_DISTRACTIONS, places: newPlaces },
                  });
                }}
                onTogglePatientPlacePicker={async () => {
                  if (data.APP_STATE.currentEditor === 'PATIENT__SOCIAL_PLACE_PICKER') {
                    await setEditor('CLINICIAN');
                  } else {
                    await setEditor('PATIENT__SOCIAL_PLACE_PICKER');
                  }
                }}
                isPatientPlacePickerActive={
                  data.APP_STATE.currentEditor === 'PATIENT__SOCIAL_PLACE_PICKER'
                }
              />
            </View>
          }
        />
        <Step
          testID="ClinicianMyPlanForm_step_helpContacts"
          num={4}
          title="People I can ask for help"
          description="These are people you can reach out to when you need help if you are reaching or in a crisis."
          viewChildren={
            <View>
              {data.HELP_CONTACTS.map((contact) => (
                <Fragment key={contact.ID}>
                  <ContactListItem
                    imageSize={40}
                    imageBorderRadius
                    nameWeight="normal"
                    name={{ first: contact.firstName, last: contact.lastName }}
                  />
                  <Divider />
                </Fragment>
              ))}
            </View>
          }
          editChildren={
            <ContactPicker
              showHeader={false}
              value={data.HELP_CONTACTS}
              onChangeValue={(newContacts) => {
                onSetDirty({
                  HELP_CONTACTS: newContacts,
                });
              }}
              onTogglePatientContactPicker={async () => {
                if (data.APP_STATE.currentEditor === 'PATIENT__HELP_CONTACT_PICKER') {
                  await setEditor('CLINICIAN');
                } else {
                  await setEditor('PATIENT__HELP_CONTACT_PICKER');
                }
              }}
              isPatientContactPickerActive={
                data.APP_STATE.currentEditor === 'PATIENT__HELP_CONTACT_PICKER'
              }
            />
          }
        />
        <Step
          testID="ClinicianMyPlanForm_step_professionalHelp"
          num={5}
          title="Professional help"
          description="These are professionals who can help you in a crisis."
          viewChildren={
            <View>
              {data.PROFESSIONAL_HELP_CONTACTS.map(({ contact }) => (
                <Fragment key={contact.ID}>
                  <ContactListItem
                    imageSize={40}
                    imageBorderRadius
                    nameWeight="normal"
                    name={{ first: contact.firstName, last: contact.lastName }}
                    image={contact.imageBase64}
                  />
                  <Divider />
                </Fragment>
              ))}
            </View>
          }
          editChildren={
            <ContactPicker
              showHeader={false}
              value={data.PROFESSIONAL_HELP_CONTACTS.map(({ contact }) => contact)}
              onChangeValue={(newContacts) => {
                onSetDirty({
                  PROFESSIONAL_HELP_CONTACTS: newContacts.map((c) => ({
                    contact: c,
                    location: BLANK_LOCATION,
                  })),
                });
              }}
              onTogglePatientContactPicker={async () => {
                if (data.APP_STATE.currentEditor === 'PATIENT__PROFESSIONAL_CONTACT_PICKER') {
                  await setEditor('CLINICIAN');
                } else {
                  await setEditor('PATIENT__PROFESSIONAL_CONTACT_PICKER');
                }
              }}
              isPatientContactPickerActive={
                data.APP_STATE.currentEditor === 'PATIENT__PROFESSIONAL_CONTACT_PICKER'
              }
            />
          }
        />
      </View>
      <View
        style={{
          borderRadius: 10,
          padding: 24,
          borderWidth: 1,
          borderColor: theme.color.gray600,
        }}
        spacing={46}
      >
        <Step
          testID="ClinicianMyPlanForm_step_safetyPlan"
          title="Making your environment safe"
          description="This is a plan that outlines steps to be taken to secure potentially lethal means for suicide."
          num={null}
          viewChildren={
            <View spacing={35}>
              <View spacing={15}>
                <Text text="Removal steps" color={theme.color.gray300} weight="semibold" />
                <UnorderedList
                  items={data.ENVIRONMENT_SAFETY.removalSteps.map((v) => v.text)}
                  color={theme.color.accentTwo100}
                  weight="normal"
                />
              </View>
              <View spacing={15}>
                <Text
                  text="Who will help me with these steps"
                  color={theme.color.gray300}
                  weight="semibold"
                />
                {data.ENVIRONMENT_SAFETY.supportContacts.map((contact) => (
                  <Fragment key={contact.ID}>
                    <ContactListItem
                      imageSize={40}
                      imageBorderRadius
                      nameWeight="normal"
                      name={{ first: contact.firstName, last: contact.lastName }}
                    />
                    <Divider />
                  </Fragment>
                ))}
              </View>
            </View>
          }
          editChildren={
            <View spacing={35}>
              <View spacing={15}>
                <Text text="Removal steps" color={theme.color.gray300} weight="semibold" />
                <ListItemTextInput
                  testID="ClinicianMyPlanForm_removalStepsInput"
                  aria-label="Removal steps"
                  value={data.ENVIRONMENT_SAFETY.removalSteps}
                  onChangeValue={(newRemovalSteps) =>
                    onSetDirty({
                      ENVIRONMENT_SAFETY: {
                        ...data.ENVIRONMENT_SAFETY,
                        removalSteps: newRemovalSteps,
                      },
                    })
                  }
                  minHeight={100}
                  bulletColor={theme.color.accentTwo100}
                />
              </View>
              <View spacing={15}>
                <Text
                  text="Who will help me with these steps"
                  color={theme.color.gray300}
                  weight="semibold"
                />
                <ContactPicker
                  showHeader={false}
                  value={data.ENVIRONMENT_SAFETY.supportContacts}
                  onChangeValue={(newContacts) => {
                    onSetDirty({
                      ENVIRONMENT_SAFETY: {
                        ...data.ENVIRONMENT_SAFETY,
                        supportContacts: newContacts,
                      },
                    });
                  }}
                  onTogglePatientContactPicker={async () => {
                    if (data.APP_STATE.currentEditor === 'PATIENT__SUPPORT_CONTACT_PICKER') {
                      await setEditor('CLINICIAN');
                    } else {
                      await setEditor('PATIENT__SUPPORT_CONTACT_PICKER');
                    }
                  }}
                  isPatientContactPickerActive={
                    data.APP_STATE.currentEditor === 'PATIENT__SUPPORT_CONTACT_PICKER'
                  }
                />
              </View>
            </View>
          }
        />
      </View>
    </View>
  );
}
