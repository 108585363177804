import { useCallback, useRef } from 'react';

import {
  useCompositionByID,
  useCompositions,
  useCreateComposition,
  useUpdateCompositionSection,
} from '@oui/app-core/src/hooks/useComposition';
import { CSSRS, NumericDiagnostic } from '@oui/lib/src/types/avro';
import { CompositionTemplate } from '@oui/lib/src/types/graphql.generated';
import { GQLUUID } from '@oui/lib/src/types/scalars';

export type Assessment = NumericDiagnostic | CSSRS;
export type AssessmentCompositionSection = { ID: GQLUUID; title: string | null; json: Assessment };

export function isPHQ9(assessment: Assessment): assessment is NumericDiagnostic {
  return assessment.type === 'PHQ9';
}

export function assessmentResultsString(assessment: Assessment): string | null {
  if (assessment.type !== 'CSSRS') {
    const score = assessment.responses.reduce((carry, v) => carry + v, 0);
    if (assessment.type === 'PHQ9') {
      if (score <= 4) {
        return `${score} - Minimal`;
      } else if (score <= 9) {
        return `${score} - Mild`;
      } else if (score <= 14) {
        return `${score} - Moderate`;
      } else if (score <= 19) {
        return `${score} - Moderately severe`;
      } else if (score <= 27) {
        return `${score} - Severe`;
      }
    } else if (assessment.type === 'BECK') {
      if (score <= 10) {
        return `${score} - Normal`;
      } else if (score <= 16) {
        return `${score} - Mild`;
      } else if (score <= 20) {
        return `${score} - Borderline`;
      } else if (score <= 30) {
        return `${score} - Moderate`;
      } else if (score <= 40) {
        return `${score} - Severe`;
      } else {
        return `${score} - Extreme`;
      }
    } else if (assessment.type === 'BSCS' || assessment.type === 'SCS') {
      return `${score}`;
    }
  }

  return null;
}

export function useAssessmentByID({ compositionID }: { compositionID: GQLUUID }) {
  const { data, refetch, ...rest } = useCompositionByID({ compositionID: compositionID });
  const [updateCompositionSection] = useUpdateCompositionSection();
  const dataRef = useRef(data);
  dataRef.current = data;

  const update = useCallback(
    async (newData: Assessment) => {
      const result = await updateCompositionSection({
        variables: { sectionID: dataRef.current?.compositionByID?.sections[0].ID!, text: newData },
      });
      await refetch();
      return result;
    },
    [updateCompositionSection, refetch],
  );

  return {
    ...rest,
    refetch,
    update,
    data: data
      ? {
          compositionID: data.compositionByID?.ID,
          compositionSectionID: data.compositionByID?.sections[0].ID,
          assessment: data.compositionByID?.sections[0].json as Assessment,
        }
      : undefined,
  };
}

export function useAssessments({ patientID }: { patientID: GQLUUID }) {
  const { data, ...rest } = useCompositions({ patientID, template: 'SCREENER%' });
  return {
    ...rest,
    data: data?.compositions.map((c) => ({
      compositionID: c.ID,
      compositionSectionID: c.sections[0].ID,
      assessment: c.sections[0].json as Assessment,
    })),
  };
}

export function useCreateAssessment({ patientID }: { patientID: GQLUUID }) {
  const [createComposition] = useCreateComposition();
  const [updateCompositionSection] = useUpdateCompositionSection();

  return useCallback(
    async (type: Assessment['type']) => {
      const template =
        type === 'CSSRS'
          ? CompositionTemplate.SCREENER_CSSRS
          : CompositionTemplate.SCREENER_NUMERIC;

      const result = await createComposition({
        variables: {
          patientID,
          // title: 'SCREENER',
          template,
        },
      });

      if (
        result.data?.newCompositionWithTemplate &&
        template !== CompositionTemplate.SCREENER_CSSRS
      ) {
        const compositionSection = result.data.newCompositionWithTemplate
          .sections[0] as AssessmentCompositionSection;
        compositionSection.json.type = type;
        await updateCompositionSection({
          variables: { sectionID: compositionSection.ID, text: compositionSection.json },
        });

        result.data.newCompositionWithTemplate;
      }

      return result;
    },
    [createComposition, updateCompositionSection, patientID],
  );
}
